(function ( $ ) {

	ClashFilms.mainNavMenu = {
		init : function () {
			var _this = ClashFilms.mainNavMenu;

			_this.$navMenu = $( '#primary-menu' );
			_this.$btnNavMenuToggler = $( '#main-menu-toggler' );


			if ( _this.$navMenu.length < 1 || _this.$btnNavMenuToggler.length < 1 ) {
				console.error( '[ClashFilms.mainNavMenu] I cannot seem to find the main menu toggler' );
				return;
			}


			_this.bindEvents();
		},

		bindEvents : function () {
			var _this = ClashFilms.mainNavMenu;

			_this.$btnNavMenuToggler.on( 'touchstart.mainNavMenu click.mainNavMenu ', function ( e ) {

				if(e.type === 'touchstart') {
					// Don't trigger mouseenter even if they hold
					e.stopImmediatePropagation();
					// If $item is a link (<a>), don't go to said link on mobile, show menu instead
					e.preventDefault();
				}

				// $('body').prepend('<div style="background-color: #0e90d2; ">Pressed  ... is open: ' + _this.$navMenu.hasClass( 'menu-open' )  + '   type was: ' + e.type + '</div>');


				if ( _this.$navMenu.hasClass( 'menu-open' ) ) {
					_this.hideMenu();
				} else {
					_this.showMenu();
				}

			} );

		},

		showMenu : function () {
			var _this = ClashFilms.mainNavMenu;

			ClashFilms.$body.add( _this.$navMenu ).addClass( 'menu-open' );

			_this.$navMenu.add( _this.$btnNavMenuToggler ).attr( 'aria-expanded', true );

			ClashFilms.$body.addClass('no-scroll');

		},

		hideMenu : function () {
			var _this = ClashFilms.mainNavMenu;

			ClashFilms.$body.add( _this.$navMenu ).removeClass( 'menu-open' );
			_this.$navMenu.add( _this.$btnNavMenuToggler ).attr( 'aria-expanded', false );

			ClashFilms.$body.removeClass('no-scroll');

		}
	}

	$( function () {

	} );
})( jQuery );