(function ( $ ) {

	ClashFilms.animations = {
		init : function () {
			var _this = ClashFilms.animations;

			if ( typeof ScrollMagic === 'undefined' ) {
				console.error( '[ClashFilms.animations] We need ScrollMagic for these animations to work.' );
				return;
			}

			_this.desktopBreakpoint = 767;

			_this.gsapFix();

			_this.scrollMagicController = new ScrollMagic.Controller();

			// the various animations
			_this.slidingWords();
			_this.contactMapParrallax();

			_this.homeProjectTitles();
			_this.backgroundClash();
			_this.backgroundClashHome();

			if ( ClashFilms.$window.width > _this.desktopBreakpoint ) {
				_this.aboutReelParallax();
				_this.aboutIntroBg();
			}


			// doc.ready
			$( function () {
				// the various animations
			} );
		},


		gsapFix : function () {
			// https://greensock.com/forums/topic/9887-tween-paused-when-switch-to-other-tabs/?do=findComment&comment=39794

			window.addEventListener( 'blur', function () {
				TweenMax.ticker.useRAF( false );
			}, false );


			window.addEventListener( 'focus', function () {
				TweenMax.ticker.useRAF( true );
			}, false );
		},

		homeProjectTitles : function () {
			var _this = ClashFilms.animations;

			_this.$homeProjectTitles = $( '.home' ).find( '.projects-listing' ).find( '.project-client' );

			if ( _this.$homeProjectTitles.length < 1 ) {
				// none on this page / wrong page
				return;
			}


			_this.$homeProjectTitles.each( function ( i, el ) {
				var $parent = $( this ).parents( '.clash-project' );

				if ( $parent.length < 1 ) {
					return true; // next in a .each() loop
				}

				var parent = $parent[ 0 ];

				var animationTimeline = new TimelineMax();
				animationTimeline.fromTo( el, 1, { ease : Power4.easeIn, y : '70%' }, { y : '-10%' } );

				var scene = new ScrollMagic.Scene( {
					triggerElement : parent,
					offset         : '20%',
					duration       : '60%'
				} )
					.setTween( animationTimeline ) // trigger a TweenMax.to tween
					// .addIndicators( { name : 'Tween #' + i } ) // add indicators (requires plugin)
					.addTo( _this.scrollMagicController );
			} );

		},


		backgroundClash : function () {
			var _this = ClashFilms.animations;


			// We want different animations on the home page so we're moving it into its own function backgroundClashHome()
			if ( ClashFilms.$body.hasClass( 'home' ) ) {
				return;
			}

			_this.$cla = $( '.bg-clash-cla' );
			_this.$ash = $( '.bg-clash-ash' );
			_this.$allClash = _this.$cla.add( _this.$ash );

			if ( _this.$allClash.length < 1 ) {
				// none on this page / wrong page
				return;
			}

			// defaults are based on "À propos"
			var offset = '-100%',
				duration = '80%',
				yStart = '70%',
				yEnd = '-10%';

			if ( ClashFilms.$body.hasClass( 'single-clashfilms_project' ) ) {

				// We only want to do this when there are MANY pictures
				var $section = _this.$cla.parents( '.full-width-row' );
				if ( !$section.hasClass( 'show-clash-bg' ) ) {
					return;
				}


				offset = '20%';
				duration = '140%';
				yStart = '30%';
				yEnd = '-110%';
			}


			_this.$allClash.each( function ( i, el ) {
				var $parent = $( this ).parents( '.full-width-row' );

				if ( $parent.length < 1 ) {
					return true; // next in a .each() loop
				}

				var parent = $parent[ 0 ];

				var animationTimeline = new TimelineMax();
				animationTimeline.fromTo( el, 0.5, { y : yStart }, { y : yEnd } );

				var scene = new ScrollMagic.Scene( {
					triggerElement : parent,
					duration       : duration,
					offset         : offset,
				} )
					.setTween( animationTimeline ) // trigger a TweenMax.to tween
					// .addIndicators( { name : 'Clash BG #' + i } ) // add indicators (requires plugin)
					.addTo( _this.scrollMagicController );
			} );

		},

		backgroundClashHome : function () {
			var _this = ClashFilms.animations;

			if ( !ClashFilms.$body.hasClass( 'home' ) ) {
				return;
			}

			_this.$cla = $( '.bg-clash-cla' );
			_this.$ash = $( '.bg-clash-ash' );

			if ( _this.$cla < 1 || _this.$ash.length < 1 ) {
				// none on this page / wrong page
				return;
			}


			var $claPparent = _this.$cla.parents( '.full-width-row' ),
				$ashPparent = _this.$ash.parents( '.full-width-row' );


			if ( $claPparent.length < 1 || $ashPparent.length < 1 ) {
				// nothing to work with
				return;
			}

			var cla = _this.$cla[ 0 ],
				claParent = $claPparent[ 0 ],
				ash = _this.$ash[ 0 ],
				ashParent = $ashPparent[ 0 ];


			var claAnimationTimeline = new TimelineMax(),
				ashAnimationTimeline = new TimelineMax();


			// claAnimationTimeline.fromTo( cla, 1, { y : '-80%' }, { y : '500%' } );
			// ashAnimationTimeline.fromTo( ash, 0.5, { y : '-540%' }, { y : '350%' } );

			claAnimationTimeline.fromTo( cla, 1, { y : '90%' }, { y : '-100%' } );
			ashAnimationTimeline.fromTo( ash, 1, { y : '-350%' }, { y : '420%' } );

			// CLA
			var claScene = new ScrollMagic.Scene( {
				triggerElement : claParent,
				duration       : '200%',
				offset         : '-50%',
			} )
				.setTween( claAnimationTimeline ) // trigger a TweenMax.to tween
				// .addIndicators( { name : 'Clash BG #CLA' } ) // add indicators (requires plugin)
				.addTo( _this.scrollMagicController );

			// ASH
			var ashScene = new ScrollMagic.Scene( {
				triggerElement : ashParent,
				duration       : '250%',
				offset         : '0%'
			} )
				.setTween( ashAnimationTimeline ) // trigger a TweenMax.to tween
				// .addIndicators( { name : 'Clash BG #ash' } ) // add indicators (requires plugin)
				.addTo( _this.scrollMagicController );

		},


		aboutReelParallax : function () {
			var _this = ClashFilms.animations;

			_this.$reel = $( '.page-template-template-a-propos' ).find( '.page-introduction' ).find( '.clash-video-launcher' );

			if ( _this.$reel.length < 1 ) {
				// none on this page / wrong page
				return;
			}


			var $parent = _this.$reel.parents( '.page-introduction' );
			if ( $parent.length < 1 ) {
				return true; // next in a .each() loop
			}

			var parent = $parent[ 0 ];

			var animationTimeline = new TimelineMax();
			animationTimeline.fromTo( _this.$reel[ 0 ], 1, { y : '20%' }, { y : '-15%' } );

			var scene = new ScrollMagic.Scene( {
				triggerElement : parent,
				duration       : '50%'
			} )
				.setTween( animationTimeline ) // trigger a TweenMax.to tween
				// .addIndicators( { name : 'Reel tween' } ) // add indicators (requires plugin)
				.addTo( _this.scrollMagicController );

		},

		aboutIntroBg : function () {
			var _this = ClashFilms.animations;


			_this.$bg = $( '.page-template-template-a-propos' ).find( '.page-introduction' ).find( '.intro-bg' );

			if ( _this.$bg.length < 1 ) {
				// none on this page / wrong page
				return;
			}


			var $parent = _this.$bg.parents( '.page-introduction' );
			// console.log( _this.$bg, '$parent', $parent );
			if ( $parent.length < 1 ) {
				return true; // next in a .each() loop
			}

			var parent = $parent[ 0 ];

			var animationTimeline = new TimelineMax();
			animationTimeline.fromTo( _this.$bg[ 0 ], 1, { y : '0' }, { y : '-20%' } );

			var scene = new ScrollMagic.Scene( {
				triggerElement : parent,
				offset         : '250%',
				duration       : '50%'
			} )
				.setTween( animationTimeline ) // trigger a TweenMax.to tween
				// .addIndicators( { name : 'BG tween' } ) // add indicators (requires plugin)
				.addTo( _this.scrollMagicController );

		},


		slidingWords : function () {
			var _this = ClashFilms.animations;

			_this.tweenSpeed = 2; // in seconds
			_this.previousWord = '';

			_this.$slidingTitle = $( '.page-template-template-a-propos' ).find( '.page-introduction' ).find( '.section-title' );

			if ( _this.$slidingTitle.length < 1 ) {
				return;
			}

			_this.$slidingTitleHtmlPieces = _this.$slidingTitle.find( '.title-pieces' );
			_this.slidingWordsInfo = _this.$slidingTitle.data( 'title-pieces' );


			if ( _this.slidingWordsInfo.length < 2 ) {
				// nothing to do here
				return;
			}

			_this.slidingWords = _this.slidingWordsInfo.map( function ( wordPiece, i ) {
				return wordPiece.text;
			} );


			var currentIndex = 0;


			// console.log( wordPieces );

			_this.$slidingTitleHtmlPieces
				.html( '' )
				.addClass( 'animated' );

			// $( window ).on( 'resize.slidingWords', ClashFilms.utils.debounce( adjustForLongestWord, 250 ) );
			// adjustForLongestWord();

			changeWords();


			function adjustForLongestWord() {


				// var longestWord = _this.currentWord.length > _this.previousWord.length ? _this.currentWord : _this.previousWord;

				var $testWord = $( '<span class="word">' + _this.currentWord + '&nbsp;</span>' ).appendTo( _this.$slidingTitleHtmlPieces ),
					maxWidth = $testWord.outerWidth();

				$testWord.remove();

				_this.$slidingTitleHtmlPieces.width( maxWidth );

			}


			function changeWords() {


				if ( currentIndex >= _this.slidingWords.length - 1 ) {
					currentIndex = 0;
				} else {
					currentIndex++;
				}

				var wordInfo = _this.slidingWordsInfo[ currentIndex ],
					wordText = wordInfo.text,
					wordColour = ClashFilms.utils.hexToRgb( wordInfo.colour ),
					wordColourRgba = ClashFilms.utils.rgbToCss( wordColour, 1 ),
					wordShadowRgba = ClashFilms.utils.rgbToCss( wordColour, 0.4 ),
					wordShadowHiddenRgba = ClashFilms.utils.rgbToCss( wordColour, 0 ),

					$wordElement = $( '<span class="word">' + wordText + '</span>' ).appendTo( _this.$slidingTitleHtmlPieces );

				_this.currentWord = wordText;
				adjustForLongestWord();

				// console.log( wordText );

				var timeline = new TimelineMax( {
					onComplete : function () {
						// changeWords();
						_this.previousWord = wordText;
						$wordElement.remove();
						delete this;
					}
				} );

				var normalY = '-37%';


				timeline.fromTo( $wordElement, 0.2, {
					ease       : Back.easeInOut,
					y          : '200%',
					color      : wordShadowHiddenRgba,
					textShadow : '0px 0px 2px ' + wordShadowRgba + ' , ' +
						'0px 2px 2px ' + wordShadowRgba + ', ' +
						'0px 5px 2px ' + wordShadowRgba + ', ' +
						'0px 8px 2px ' + wordShadowRgba + ', ' +
						'0px -2px 2px ' + wordShadowRgba + ', ' +
						'0px -5px 2px ' + wordShadowRgba + ', ' +
						'0px -8px 2px ' + wordShadowRgba
				}, {
					ease       : Back.easeInOut,
					y          : normalY,
					color      : wordColourRgba,
					textShadow : '0px 0px 2px ' + wordShadowHiddenRgba + ' , ' +
						'0px 2px 2px ' + wordShadowHiddenRgba + ', ' +
						'0px 5px 2px ' + wordShadowHiddenRgba + ', ' +
						'0px 8px 2px ' + wordShadowHiddenRgba + ', ' +
						'0px -2px 2px ' + wordShadowHiddenRgba + ', ' +
						'0px -5px 2px ' + wordShadowHiddenRgba + ', ' +
						'0px -8px 2px ' + wordShadowHiddenRgba
				} )
					.to( $wordElement, ClashFilmsConfig.sliding_words_speed, {
						y          : normalY,
						color      : wordColourRgba,
						textShadow : '0px 0px 2px ' + wordShadowHiddenRgba + ' , ' +
							'0px 2px 2px ' + wordShadowHiddenRgba + ', ' +
							'0px 5px 2px ' + wordShadowHiddenRgba + ', ' +
							'0px 8px 2px ' + wordShadowHiddenRgba + ', ' +
							'0px -2px 2px ' + wordShadowHiddenRgba + ', ' +
							'0px -5px 2px ' + wordShadowHiddenRgba + ', ' +
							'0px -8px 2px ' + wordShadowHiddenRgba
					} )

					.to( $wordElement, 0.05, {
						y          : normalY,
						color      : wordShadowHiddenRgba,
						textShadow : '0px 0px 2px ' + wordShadowRgba + ' , ' +
							'0px 2px 2px ' + wordShadowRgba + ', ' +
							'0px 5px 2px ' + wordShadowRgba + ', ' +
							'0px 8px 2px ' + wordShadowRgba + ', ' +
							'0px -2px 2px ' + wordShadowRgba + ', ' +
							'0px -5px 2px ' + wordShadowRgba + ', ' +
							'0px -8px 2px ' + wordShadowRgba
					} )
					.to( $wordElement, 0.15, {
						ease       : Back.easeInOut,
						y          : '-220%',
						color      : wordShadowHiddenRgba,
						textShadow : '0px 0px 2px ' + wordShadowRgba + ' , ' +
							'0px 2px 2px ' + wordShadowRgba + ', ' +
							'0px 5px 2px ' + wordShadowRgba + ', ' +
							'0px 8px 2px ' + wordShadowRgba + ', ' +
							'0px -2px 2px ' + wordShadowRgba + ', ' +
							'0px -5px 2px ' + wordShadowRgba + ', ' +
							'0px -8px 2px ' + wordShadowRgba
					} )
					.call( changeWords )


				timeline.play();
			}

		},

		contactMapParrallax : function () {
			var _this = ClashFilms.animations;

			_this.$map = $( '.page-template-template-contact' ).find( '.map-container' );

			if ( _this.$map.length < 1 ) {
				// none on this page / wrong page
				return;
			}


			var $parent = _this.$map.parents( '.main-content' );
			// console.log( _this.$bg, '$parent', $parent );
			if ( $parent.length < 1 ) {
				return true; // next in a .each() loop
			}

			var parent = $parent[ 0 ];

			var animationTimeline = new TimelineMax();
			animationTimeline.fromTo( _this.$map[ 0 ], 1, { y : '20%' }, { y : '0%' } );

			var scene = new ScrollMagic.Scene( {
				triggerElement : parent,
				offset         : '40%',
				duration       : '80%'
			} )
				.setTween( animationTimeline ) // trigger a TweenMax.to tween
				// .addIndicators( { name : 'BG tween' } ) // add indicators (requires plugin)
				.addTo( _this.scrollMagicController );
		}
	}


	$( function () {

	} );
})( jQuery );