(function ( $ ) {
	ClashFilms.homeHeroVideo = {

		minWidthToShow : 768,

		init : function () {
			var _this = ClashFilms.homeHeroVideo;

			if ( !ClashFilms.$body.hasClass( 'home' ) ) {
				// this script only applies to the home page
				return;
			}

			if ( ClashFilms.$window.width() < _this.minWidthToShow ) {
				// no videos on mobile, please
				return;
			}

			_this.haveVideoInfo = _this.gatherVideoInfo();
			if ( !_this.haveVideoInfo ) {
				return;
			}


			// At this point, we're ready to rock!
			_this.applyIframe();

		},

		gatherVideoInfo : function () {
			var _this = ClashFilms.homeHeroVideo;


			_this.$heroSection = $( '#home-hero' );
			if ( _this.$heroSection.length < 1 ) {
				// Weird that there's no hero section.. still nothing for us to do here
				return false;
			}

			_this.vimeoCodeIframe = _this.$heroSection.data( 'video-bg' );

			if ( !_this.vimeoCodeIframe || _this.vimeoCodeIframe === '' ) {
				//no iFrame code, not much we can do without a video
				return false;
			}

			return true;
		},

		applyIframe : function () {
			var _this = ClashFilms.homeHeroVideo;

			_this.$insertedIframe = $( '<div class="hero-iframe-container">' + _this.vimeoCodeIframe + '</div>' ).prependTo( _this.$heroSection );


			$( window ).on( 'resize.homeHeroIframe', ClashFilms.utils.debounce( function () {
				_this.makeSureItFills( _this.$insertedIframe, _this.$heroSection );
			}, 0 ) );
			$( window ).trigger( 'resize.homeHeroIframe' );

			_this.$insertedIframe
				.hide()
				.fadeIn();
		},


		makeSureItFills : function ( $element, $parent ) {
			var _this = ClashFilms.homeHeroVideo;

			var winHeight = $parent.height(),
				winWidth = $parent.width(),
				vidRatio = 16 / 9,
				vidWidthCalRatio = 9 / 16,
				safetyRatio = 1,
				maxHeight = winWidth / vidRatio * safetyRatio,
				calcWidth = Math.floor( (winHeight * safetyRatio) * vidRatio ),
				doesItFit = maxHeight > winHeight;


			if ( calcWidth < winWidth ) {
				$element.outerWidth( Math.floor( winWidth * safetyRatio ) )
					.height( Math.floor( winWidth * safetyRatio ) * vidWidthCalRatio );

			} else if ( !doesItFit ) {
				$element.outerWidth( calcWidth )
					.height( calcWidth * vidWidthCalRatio );
			} else {
				$element.outerWidth( Math.floor( winWidth * safetyRatio ) )
					.height( Math.floor( winWidth * safetyRatio ) * vidWidthCalRatio );
			}


		}
	}
})( jQuery );