(function ( $ ) {

	ClashFilms.replaceProjectVideoOnLoad = {
		init : function () {
			var _this = ClashFilms.replaceProjectVideoOnLoad;


			_this.$videosToReplace = $( '.video-replace-on-load' );

			if ( _this.$videosToReplace.length < 1 ) {
				// nothing to do here
				return;
			}

			_this.$videosToReplace.each( function ( i, el ) {
				var iframeCode = $( this ).data( 'video-code' );

				if ( iframeCode && iframeCode !== '' ) {
					$( this ).html( iframeCode );
				}
			} )
		}
	}


	$( function () {
	} );
})( jQuery );