/*!
* Scripts for Clash Films' site
*
* This (_utils.js) should be first in the build process
 */

if ( typeof ClashFilms === 'undefined' ) {
	ClashFilms = {};
}
(function ( $ ) {


	ClashFilms.$body = ClashFilms.$body || $( 'body' );
	ClashFilms.$window = ClashFilms.$window || $( window );

	ClashFilms.utils = {

		// https://davidwalsh.name/javascript-debounce-function
		debounce : function debounce( func, wait, immediate ) {
			var timeout;
			return function () {
				var context = this, args = arguments;
				var later = function () {
					timeout = null;
					if ( !immediate ) func.apply( context, args );
				};
				var callNow = immediate && !timeout;
				clearTimeout( timeout );
				timeout = setTimeout( later, wait );
				if ( callNow ) func.apply( context, args );
			}
		},

		// https://stackoverflow.com/a/5624139
		hexToRgb : function ( hex ) {
			// Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
			var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
			hex = hex.replace( shorthandRegex, function ( m, r, g, b ) {
				return r + r + g + g + b + b;
			} );

			var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec( hex );
			return result ? {
				r : parseInt( result[ 1 ], 16 ),
				g : parseInt( result[ 2 ], 16 ),
				b : parseInt( result[ 3 ], 16 )
			} : null;
		},
		rgbToCss : function ( rgb, alpha ) {

			if ( typeof alpha === 'undefined' || alpha === '' ) {
				return 'rgb(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ')';
			} else {
				// in case they went with 80 instead of 0.8
				if ( alpha > 1 ) {
					alpha = alpha / 100;
				}

				return 'rgb(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', ' + alpha + ')';
			}

		}
	}
})( jQuery );


