(function ( $ ) {

	ClashFilms.clientCarousel = {
		init : function () {
			var _this = ClashFilms.clientCarousel;

			if ( typeof $.fn.slick === 'undefined' ) {
				console.error( '[ClashFilms.clientCarousel] We need Slick for this module to run.' );
				return;
			}

			_this.$clientsContainer = $( '.footer-clients' );

			if ( _this.$clientsContainer.length < 1 ) {
				// Nothing to apply the carousel to
				return;
			}

			_this.setupCarousel();
		},

		setupCarousel : function () {
			var _this = ClashFilms.clientCarousel;

			_this.$clientsContainer.slick( {
				autoplay      : true,
				autoplaySpeed : (ClashFilmsConfig.carousel_speed || 6) * 1000,

				slidesPerRow   : 4,
				rows           : 2,
				slidesToScroll : 0.25,
				responsive     : [
					{
						breakpoint : 767,
						settings   : {
							slidesPerRow   : 2,
							rows           : 2,
							slidesToScroll : 0.5,
						}
					}
				]
			} )
		}
	}


	$( function () {
	} );
})( jQuery );