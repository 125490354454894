(function ( $ ) {
	ClashFilms.videoLightbox = {

		init       : function () {
			var _this = ClashFilms.videoLightbox;

			_this.zoomInSpeed = 750; // in ms

			_this.$btnVideoLaunchers = $( '.clash-video-launcher' );

			if ( _this.$btnVideoLaunchers.length < 1 ) {
				return;
			}

			ClashFilms.$body = $( 'body' );


			_this.bindEvents();
		},
		bindEvents : function () {
			var _this = ClashFilms.videoLightbox;

			_this.$btnVideoLaunchers.on( 'click', function ( e ) {
				e.preventDefault();

				var $this = $( this ),
					videoInfo = $this.data( 'video' );

				_this.displayLightbox( $this );

				if ( !videoInfo ) {
					return false;
				}
			} );

			ClashFilms.$body.on( 'click', '.animated-lightbox-bg', function ( e ) {

				console.log('Close or lightbox clicked',e.target );

				if ( $( e.target ).hasClass( 'animated-lightbox-bg' ) || $( e.target ).hasClass( 'btn-lightbox-close' ) ) {
					_this.closeLightbox();
				}
			} );
		},

		displayLightbox : function ( $clicked, videoData ) {
			var _this = ClashFilms.videoLightbox;

			var zoomFromElement = $clicked.hasClass( 'zoom-from-element' );

			if ( zoomFromElement ) {
				var originalOffset = $clicked.offset(),
					originalLeft = originalOffset.left,
					originalTop = originalOffset.top,
					scrollTop = $( window ).scrollTop(),
					originalScreenTop = originalTop - scrollTop,
					originalWidth = $clicked.outerWidth(),
					originalHeight = $clicked.outerHeight(),
					originalBorder = parseInt( $clicked.css( 'border-width' ) ),
					originalBorderColour = $clicked.css( 'border-color' );



				_this.$lightbox = $( '<div class="animated-lightbox-bg" />' ).appendTo( ClashFilms.$body );

				_this.$lightbox.css( {
					position : 'fixed',
					top      : originalScreenTop,
					left     : originalLeft,
					width    : originalWidth,
					height   : originalHeight,
					opacity  : 0.4
				} );


				_this.$lightbox.addClass( 'active' ).animate( {
					top     : 0,
					left    : 0,
					width   : '100%',
					height  : '100%',
					opacity : 1,
				}, _this.zoomInSpeed, 'easeInOutQuart', function () {
					_this.setupLightbox( $clicked );
				} );

			} else {
				_this.$lightbox = $( '<div class="animated-lightbox-bg" />' ).appendTo( ClashFilms.$body );

				_this.$lightbox.addClass( 'active' ).css( {
					position : 'fixed',
					top      : 0,
					left     : 0,
					width    : '100%',
					height   : '100%',
					opacity  : 0.4,
				} );

				_this.$lightbox.addClass( 'active' ).animate( {
					opacity : 1,
				}, _this.zoomInSpeed, 'easeInOutQuart', function () {
					_this.setupLightbox( $clicked );
				} );
			}

		},


		setupLightbox : function ( $clicked ) {
			var _this = ClashFilms.videoLightbox;

			var videoData = $clicked.data( 'video-code' );

			var $closeBtn = $( '<button type="button" class="btn-lightbox-close"><span class="sr-only">' + ClashFilmsConfig.translations.lightbox_close + '</span></button>' ).appendTo( _this.$lightbox ).hide();


			_this.$lightboxInner = $( '<div class="lightbox-inner" />' ).appendTo( _this.$lightbox ).hide();

			_this.$videoFrame = $( videoData ).appendTo( _this.$lightboxInner );

			_this.makeSureItFits( _this.$lightboxInner );

			$( window ).on( 'resize.videoLightbox', ClashFilms.utils.debounce( function () {
				_this.makeSureItFits( _this.$lightboxInner );
			}, 250 ) );
			$( window ).trigger( 'resize.videoLightbox' );

			ClashFilms.$body.addClass('no-scroll');

			_this.$lightboxInner.show();

			$closeBtn.fadeIn();

		},


		closeLightbox : function ( immediate ) {
			var _this = this;

			var fadeOutDelay = immediate ? 0 : 250;

			if ( typeof _this.$lightbox !== 'undefined' ) {

				if ( _this.$lightbox.length > 0 ) {
					_this.$lightbox.stop().fadeOut( fadeOutDelay, function () {
						_this.$lightbox.remove();
						delete _this.$lightbox;
					} );
				} else {
					delete _this.$lightbox;
				}

				$( window ).off( 'resize.videoLightbox' );
			}

			ClashFilms.$body.removeClass('no-scroll');
		},

		makeSureItFits : function ( $element ) {
			var _this = this;

			var $win = $( window ),
				winHeight = $win.height(),
				winWidth = $win.width(),
				vidRatio = 16 / 9,
				vidWidthCalRatio = 9 / 16,
				safetyRatio = 1,
				maxHeight = winWidth / vidRatio * safetyRatio,
				calcWidth = Math.floor( (winHeight * safetyRatio) * vidRatio ),
				doesItFit = maxHeight < winHeight;


			if ( calcWidth > winWidth ) {
				$element.outerWidth( Math.floor( winWidth * safetyRatio ) )
					.height( Math.floor( winWidth * safetyRatio ) * vidWidthCalRatio );

			} else if ( !doesItFit ) {
				$element.outerWidth( calcWidth )
					.height( calcWidth * vidWidthCalRatio );
			} else {
				$element.outerWidth( Math.floor( winWidth * safetyRatio ) )
					.height( Math.floor( winWidth * safetyRatio ) * vidWidthCalRatio );
			}


		}
	};
})( jQuery );