if ( typeof ClashFilms === 'undefined' ) {
	ClashFilms = {};
}


(function ( $ ) {

	// default
	ClashFilms.lang = 'fr';
	if ( typeof ClashFilmsConfig !== 'undefined' && typeof ClashFilmsConfig.lang !== 'undefined' ) {
		ClashFilms.lang = ClashFilmsConfig.lang;
	}

	ClashFilms.mainNavMenu.init();
	ClashFilms.videoLightbox.init();
	ClashFilms.animations.init();

	// doc.ready
	$( function () {
		objectFitImages();
		ClashFilms.replaceProjectVideoOnLoad.init();

		ClashFilms.clientCarousel.init();

		ClashFilms.homeHeroVideo.init();
	} );


})( jQuery );